<template>
  <div class="operation-box">
    <div class='img-style-box'>
      <div class='img-style-item'>
        <div class='label'>高度：</div>
        <a-slider  :default-value="0" :min='0' :max='50' :step='1' v-model="props.height" tooltipPlacement='right' style='width:180px;margin:0 12px;'/>
        <span>{{props.height}}px</span>
      </div>
      <div class='img-style-item'>
        <div class='label'>背景色：</div>
        <a-popover title="">
          <template slot="content">
            <Sketch v-model="props.bgColor" @input="e=>$set(props, 'bgColor', `rgba(${e.rgba.r},${e.rgba.g},${e.rgba.b},${e.rgba.a})`)"></Sketch>
          </template>
          <div class='bg-btn' :style='{background: props.bgColor}'></div>
        </a-popover>
      </div>
    </div>
  </div>
</template>
<script>
import { Sketch } from 'vue-color'
export default {
  props: {
    props: {
      type: Object
    }
  },
  components: {
    Sketch
  },
  setup (props, { root, emit }) {
    return {
    }
  }
}
</script>
<style lang="less" scoped>
.operation-box {
  background: #fff;
  .img-style-box {
    background: #fff;
    padding: 32px 14px 200px;
    .img-style-item {
      display: flex;
      align-items: center;
      margin-bottom: 14px;
      .bg-btn {
        width: 32px;
        height: 24px;
        border: 1px solid #ddd;
        cursor: pointer;
      }
      .label {
        width: 6em;
        text-align: right;
      }
      ::v-deep .ant-slider-handle {
        border-color: #189ff0;
      }
      ::v-deep .ant-slider-track {
        background-color: #189ff0;
      }
      ::v-deep .ant-slider-rail {
        background-color: #c1c1c1;
      }
    }
  }
}

</style>
